import React, { useEffect } from "react";
import classes from "./reviewsList.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReviewCard from "../review card/ReviewCard";
import { Link } from "react-router-dom";
import ReviewsCarousel from "../Reviews Carousel/ReviewsCarousel";
const ReviewsList = (props) => {
  const ReviewsCards = props.events;

  const ReviewsTempData = [
    {
      _id: "1",
      user: {
        image: "https://have-adream-bucket.s3.eu-north-1.amazonaws.com/nc3ciqmsql7kfwerjpol.jpg",
        fullName: "Zyad Elhalafawy",
      },
      program: {
        name: "Fly to Connect",
        location: "Kenya",
        startDate: "2024-04-01T00:00:00.000+00:00",
        endDate: "2024-04-08T00:00:00.000+00:00",
        _id: "65e58826618268452fdddc6b",
      },
      text:
        "I was so glad to be a member of the volunteer team in Kenya. I received assistance from the team all the time when I was in Kenya. They were very kind, helpful, and on time responded.",
      rating: 5,
    },
    {
      _id: "2",
      user: {
        image: "https://have-adream-bucket.s3.eu-north-1.amazonaws.com/yw3a7imwth62aonifl4p.jpg",
        fullName: "Abdelrahman Masoud",
      },
      program: {
        name: "Fly to Connect",
        location: "Zanzibar, Tanzania",
        startDate: "2024-04-15T00:00:00.000+00:00",
        endDate: "2025-12-21T00:00:00.000+00:00",
        _id: "65eef478671b961860895982",
      },
      text:
        "Life changing. It was good to know people as they were very kind. Teaching also makes you fulfilled as you see the effect on the people.",
      rating: 5,
    },
    {
      _id: "3",
      user: {
        image: "https://have-adream-bucket.s3.eu-north-1.amazonaws.com/ld1yubl6b5ylnpnwrvg0.jpg",
        fullName: "Rawia Almadni",
      },
      program: {
        name: "Fly to Connect",
        location: "Thailand",
        startDate: "2023-04-15T00:00:00.000+00:00",
        endDate: "2023-12-21T00:00:00.000+00:00",
        _id: "65ef1cbc5bb84b956d5be03d",
      },
      text:
        "I've always wanted to travel, but not as a tourist and this experience definitely satisfied my curiosity and fueled my long-standing passion for Asian cultures. I learned a lot about Thailand and its hospitable, generous people, immersed myself in the breathtaking natural landscapes, visited some amazing landmarks, learned how to make Pad Thai and even took a Muay Thai class.",
      rating: 5,
    },
  ];

  return (
    <div className={classes.main}>
      <div className={classes.secHeader}>{props.title && <h3>Reviews</h3>}</div>
      <div className={classes.container}>
        {ReviewsTempData?.map((card) => (
          <ReviewCard
            id={card?._id}
            key={card?._id}
            progId={card?.program?._id}
            img={card?.user?.image}
            title={card?.user?.fullName}
            programName={card?.program?.name}
            startDate={card?.program?.startDate}
            endDate={card?.program?.endDate}
            location={card?.program?.location}
            load={props?.load}
            content={card?.text}
            rating={card?.rating}
            nationality={card?.user?.nationality}
            email={card?.user?.email}
            phone={card?.user?.phoneNO}
            isReview={true}
          />
        ))}
        {!props.load && !props.admin && (
          <Link to="/reviews">
            <div className={classes.seeMore}>
              <div className={classes.content}>
                <p>See More</p>
                <ArrowForwardIosIcon
                  style={{ fontSize: "20" }}
                  className={classes.arrow}
                />
              </div>
            </div>
          </Link>
        )}
      </div>
      <div className={classes.alice}>
        <ReviewsCarousel events={ReviewsTempData} title={false} load={props.load} />
        <Link to="/reviews" className={classes.linkButton}>
          <div className={classes.seeMore}>
            <div className={classes.content}>
              <p>See More</p>
              <ArrowForwardIosIcon
                style={{ fontSize: "20" }}
                className={classes.arrow}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ReviewsList;
